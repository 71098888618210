<template>
    <div class="preloader">
        <v-overlay :value="overlay" absolute :color="overlayColor">
            <v-progress-circular indeterminate size="64" :color="progressColor"/>
        </v-overlay>
    </div>
</template>

<script>
export default {
  name: 'Preloader',
  props: {
    overlay: {
      type: Boolean,
      default: () => false,
    },
    overlayColor: {
      type: String,
      default: () => '#fbfbfb',
    },
    progressColor: {
      type: String,
      default: () => '#f88d4c',
    },
  },
};
</script>

<style scoped>

</style>
