<template>
    <div>
        <Preloader overlay/>
    </div>
</template>

<script>
import Preloader from '@components/Shared/Preloader.vue';

export default {
  name: 'SignInEclass',
  components: { Preloader },
  async created() {
    const { code } = this.$route.query;
    this.$store.dispatch('auth/authEclass', {
      code,
    }).then(() => {
      this._showSuccessNotify(this.$t('message.loginSuccess'));
      this.$store.dispatch('auth/auth').then(() => {
        window.location.reload();
      }).catch((e) => {
        console.log(e);
      });
    }).catch(() => {
      this._showErrorNotify(this.$t('message.loginFail'));
      this.$router.push('/sign-in');
    });
  },
};
</script>

<style lang="scss" scoped>
    .header {
        display: none;
    }
</style>
